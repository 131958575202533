import React, { PropsWithChildren, ReactElement, useEffect } from 'react'
import Helmet from "../../components/Helmet";
import TextAndImageBanner from '../../components/Widgets/TextAndImageBanner';
import { getSeoDescription, getSeoKeywords, getSeoTitle, isBrowser } from '../../shared/helpers'

interface ThankYouProps {
    data: any;
    pageContext: any;
}

const ThankYouPage = (props: PropsWithChildren<ThankYouProps>): ReactElement => {
    
    useEffect(() => {
        if (isBrowser()) {
            window.addEventListener('beforeunload', function (e) {
                e.preventDefault();
                window.history.replaceState('', "false");
            })
        }
    }, [])

    const data = props.pageContext.data;
    const { body } = data;
    return (
        <>
            <Helmet
                title={getSeoTitle(data, "Thank You")}
                description={getSeoDescription(data, "Thank You page")}
                keywords={getSeoKeywords(data, "")} 
            >
                <meta name="robots" content="noindex"/>
            </Helmet>
            {body && body[0] &&
                <TextAndImageBanner data={body[0].primary} />
            }

        </>
    )
}

export default ThankYouPage
